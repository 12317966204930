import { LitElement, css, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';

@customElement('mylib-button')
export class ButtonElement extends LitElement {

  @property({ type: String })
  label = 'Button'
  @property({ type: String })
  message = 'Hello'

    render() {

        return html`
        <button @click=${() => alert(this.message)}>
            ${this.label}
        </button>
	    `
    }

    static styles = css`
    :host {
      font-family: Verdana, Geneva, Tahoma, sans-serif;
    }
    button {
		border-radius: 8px;
		border: 1px solid;
		padding: 0.3em 1.2em;
		font-size: 1em;
		font-weight: 500;
		font-family: inherit;
		cursor: pointer;
        color: green;
		transition: border-color 0.25s;
	  }

	  button:hover {
        background-color: lightgreen;
	  }
    `
}
