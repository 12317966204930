import { LitElement, css, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { styleMap } from 'lit-html/directives/style-map.js';

@customElement('mylib-html-ellipsis')
export class HtmlEllipsisElement extends LitElement {

    @property({ type: Number })
    lines = 5;

    @property({ type: String, attribute: 'margin-bottom' })
    marginBottom = "30px";

    render() {
        const myStyles = {
            '-webkit-line-clamp': this.lines,
            'margin-bottom': this.marginBottom
        };

        return html`
            <div class="text" style=${styleMap(myStyles)}> 
                <slot></slot>
            </div> 
	    `
    }

    static styles = css`
    .text { 
        display: -webkit-box; 
        -webkit-box-orient: vertical; 
        overflow: hidden; 
        text-overflow: ellipsis; 
    } `
}
